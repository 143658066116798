


















































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import MnemonicDisplay from "@/components/misc/MnemonicDisplay2.vue";
import * as bip39 from "bip39";

@Component({
  components: {
    MnemonicDisplay,
  },
})
export default class Mnemonic extends Vue {
  phrase: string = "";
  isLoading: boolean = false;
  err: string = "";

  beforeDestroy() {
    this.phrase = "";
  }

  onPhraseIn(ev: any) {
    this.phrase = ev.currentTarget.value;
  }

  errCheck() {
    let phrase = this.phrase;
    let words = phrase.split(" ");

    // not a valid key phrase
    if (words.length !== 24) {
      this.err = `${this.$t("access.mnemonic.error")}`;
      return false;
    }

    let isValid = bip39.validateMnemonic(phrase);
    if (!isValid) {
      this.err =
        "Invalid mnemonic phrase. Make sure you double check all spellings & that all words are in lowercaps only.";
      return false;
    }

    return true;
  }

  get wordCount(): number {
    return this.phrase.trim().split(" ").length;
  }

  get canSubmit() {
    if (this.wordCount !== 24) {
      return false;
    }

    return true;
  }

  async access() {
    this.phrase = this.phrase.trim();
    let phrase = this.phrase;

    this.isLoading = true;

    if (!this.errCheck()) {
      this.isLoading = false;
      return;
    }

    setTimeout(async () => {
      try {
        await this.$store.dispatch("accessWallet", phrase);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.err = `${this.$t("access.mnemonic.error")}`;
      }
    }, 500);
  }
}
