





























import { Vue, Component } from "vue-property-decorator";
import TopInfo from "@/components/wallet/TopInfo.vue";
import Sidebar from "@/components/wallet/Sidebar.vue";
import MainPanel from "@/components/SidePanels/MainPanel.vue";
import UpdateKeystoreModal from "@/components/modals/UpdateKeystore/UpdateKeystoreModal.vue";

const TIMEOUT_DURATION = 60 * 7; // in seconds
const TIMEOUT_DUR_MS = TIMEOUT_DURATION * 1000;

@Component({
  components: {
    Sidebar,
    MainPanel,
    TopInfo,
    UpdateKeystoreModal,
  },
})
export default class Wallet extends Vue {
  intervalId: ReturnType<typeof setTimeout> | null = null;
  logoutTimestamp = Date.now() + TIMEOUT_DUR_MS;
  isLogOut = false;

  // Set the logout timestamp to now + TIMEOUT_DUR_MS
  resetTimer() {
    this.logoutTimestamp = Date.now() + TIMEOUT_DUR_MS;
  }

  checkLogout() {
    let now = Date.now();

    // Logout if current time is passed the logout timestamp
    if (now >= this.logoutTimestamp && !this.isLogOut) {
      this.isLogOut = true;
      this.$store.dispatch("timeoutLogout");
    }
  }

  created() {
    this.resetTimer();
    this.intervalId = setInterval(() => {
      this.checkLogout();
    }, 1000);
  }

  unload(event: BeforeUnloadEvent) {
    // user has no wallet saved
    if (
      !localStorage.getItem("w") &&
      this.hasVolatileWallets &&
      this.isLogOut
    ) {
      event.preventDefault();
      this.isLogOut = false;
      event.returnValue = "";
      this.$router.push("/wallet/keys");
      this.resetTimer();
    }
  }

  mounted() {
    let view = this.$refs.wallet_view as HTMLDivElement;

    view.addEventListener("mousemove", this.resetTimer);
    view.addEventListener("mousedown", this.resetTimer);

    window.addEventListener("beforeunload", this.unload);
  }

  beforeDestroy() {
    let view = this.$refs.wallet_view as HTMLDivElement;
    // Remove Event Listeners
    view.removeEventListener("mousemove", this.resetTimer);
    view.removeEventListener("mousedown", this.resetTimer);
    window.removeEventListener("beforeunload", this.unload);
  }

  destroyed() {
    clearInterval(this.intervalId!);
  }

  get isManageWarning(): boolean {
    if (this.$store.state.warnUpdateKeyfile) {
      return true;
    }
    return false;
  }

  get hasVolatileWallets() {
    return this.$store.state.volatileWallets.length > 0;
  }
}
