

























































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import SaftCheckbox from "../components/Saft/SaftCheckbox.vue";
import InputField from "../components/Saft/InputField.vue";
import MultiSigCheckbox from "../components/Saft/MultiSigCheckbox.vue";
import { SingletonWallet } from "@/js/wallets/SingletonWallet";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import { WalletNameType } from "@/js/wallets/types";
//import { generateToken, getPublicKey } from "@/kyc_api";
import snsWebSdk from "@sumsub/websdk";
import axios from "axios";
//@ts-ignore
const EC = require("elliptic").ec;

interface User {
  company: string;
  name: string;
  surname: string;
  street: string;
  street2: string;
  zip: string;
  city: string;
  country: string;
  email: string;
  phone: string;
  agree: boolean;
  multisig: boolean;
}

@Component({
  components: { SaftCheckbox, InputField, MultiSigCheckbox },
})
export default class Saft extends Vue {
  nameRegex = /^([^<>()|[\]\\.,;:@\\"0-9]|(\\".+\\")){2,64}$/;
  emailRegex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  phoneRegex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,9}$/;
  isLoading = false;
  background = "";
  error: {
    name: boolean;
    surname: boolean;
    city: boolean;
    email: boolean;
    phone: boolean;
  } = {
    name: false,
    surname: false,
    city: false,
    email: false,
    phone: false,
  };
  submitted: boolean = false;
  user: User = {
    company: "",
    name: "",
    surname: "",
    street: "",
    street2: "",
    zip: "",
    city: "",
    country: "",
    email: "",
    phone: "",
    agree: false,
    multisig: false,
  };
  created() {
    localStorage.removeItem("Email");
    localStorage.removeItem("Phone");
  }

  get walletType(): WalletNameType {
    return this.wallet.type;
  }
  get wallet() {
    let wallet: MnemonicWallet = this.$store.state.activeWallet;
    return wallet;
  }

  @Watch("$root.theme", { immediate: true })
  onthemechange(val: string) {
    if (val === "night") {
      this.background =
        ".step.active .line, .step.active .bullet:before, .radio-item .checkmark:after, .step.active.pending .bullet:before {\
    background-color: #149ded;\
}\
.accent {\
    color: #149ded;\
}\
.step .title, .title  {\
    color: #f5f5f5;\
}\
.step.active .title {\
    color: #149ded;\
    font-size: 14px;\
    font-weight: 400;\
}\
section {\
    border-radius: 12px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
    background-color: #1e293b;\
}\
p , h3, h2, label, .markdown-instructions li , .markdown-instructions p,.line-form .line-form-item > .phone-input,\
.line-form .line-form-item > input{\
    color : #f5f5f5 !important;\
    font-size : 14px;\
}\
.document-examples, .upload {\
    gap : 10px;\
}\
.upload-payment-item {\
    margin : 0px;\
}\
.upload-payment-item .upload-item , .mobile-button{\
    border: 1px solid rgba(203, 213, 225, 0.12);\
    border-radius: 7px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
}\
 .mobile-button h3{\
    color : #149ded !important;\
 }\
 button.submit,\
button[type='submit'] {\
    border-radius: 12px;\
    background-color: transparent;\
    background-image: none;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
button:active:not(:disabled):not(.disabled),\
button:hover:not(:disabled):not(.disabled):not(:active) {\
    box-shadow: none;\
}\
button.submit:active:not(:disabled),\
button.submit:hover:not(:disabled):not(.disabled):not(:active),\
button[type='submit']:active:not(:disabled),\
button[type='submit']:hover:not(:disabled):not(.disabled):not(:active) {\
     background-image: none;\
}\
button {\
    border-radius: 12px;\
    background-color: transparent;\
    font-weight: 600;\
    text-align: center;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
.line-form .line-form-item > span {\
    border-bottom: none;\
}\
button.submit .arrow, button[type=submit] .arrow {\
    margin-right: 0;\
    margin-left: 5px;\
}\
button .arrow {\
    margin-right: 5px;\
}\
.upload-item h4.requiredDoc:after {\
    color: #149ded;\
}\
.popup {\
    background: #1e293b !important;\
}\
.popup .message-content p {\
    color: #f5f5f5 !important;\
}\
.step.pending .bullet {\
    background-color: #f5f5f5;\
    background-image: none;\
    border-color: #f5f5f5;\
}\
.step.pending .line , .step.active .line, .step.success .line{\
    background-color: #149ded;\
}\
.step.success .bullet {\
    background-color: #149ded;\
    border-color: #f5f5f5;\
}\
.error-message.warn {\
    background-color: #0f172a;\
}\
.radio-item input:disabled~.checkmark:after {\
  background-color: #149ded;\
}\
.document-status {\
    background-color: transparent !important;\
}\
";
      // 'body {background-color: var(--secondary-color) !important; min-height: 450px !important;} .line {background-color: black !important;}'
    } else {
      this.background =
        ".step.active .line, .step.active .bullet:before, .radio-item .checkmark:after, .step.active.pending .bullet:before {\
    background-color: #149ded;\
}\
.accent {\
    color: #149ded;\
}\
.step .title, .title  {\
    color: #0f172a;\
}\
.step.active .title {\
    color: #149ded;\
    font-size: 14px;\
    font-weight: 400;\
}\
section {\
    border-radius: 12px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
    background-color: transparent;\
}\
p , h3, h2, label, .markdown-instructions li , .markdown-instructions p,.line-form .line-form-item > .phone-input,\
.line-form .line-form-item > input{\
    color : #0f172a !important;\
    font-size : 14px;\
}\
.document-examples, .upload {\
    gap : 10px;\
}\
.upload-payment-item {\
    margin : 0px;\
}\
.upload-payment-item .upload-item , .mobile-button{\
    border: 1px solid rgba(203, 213, 225, 0.12);\
    border-radius: 7px;\
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px;\
}\
 .mobile-button h3{\
    color : #149ded !important;\
 }\
 button.submit,\
button[type='submit'] {\
    border-radius: 12px;\
    background-color: transparent;\
    background-image: none;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
button:active:not(:disabled):not(.disabled),\
button:hover:not(:disabled):not(.disabled):not(:active) {\
    box-shadow: none;\
}\
button.submit:active:not(:disabled),\
button.submit:hover:not(:disabled):not(.disabled):not(:active),\
button[type='submit']:active:not(:disabled),\
button[type='submit']:hover:not(:disabled):not(.disabled):not(:active) {\
     background-image: none;\
}\
button {\
    border-radius: 12px;\
    background-color: transparent;\
    font-weight: 600;\
    text-align: center;\
    color: #149ded;\
    border: 1px solid #149ded;\
}\
.line-form .line-form-item > span {\
    border-bottom: none;\
}\
button.submit .arrow, button[type=submit] .arrow {\
    margin-right: 0;\
    margin-left: 5px;\
}\
button .arrow {\
    margin-right: 5px;\
}\
.upload-item h4.requiredDoc:after {\
    color: #149ded;\
}\
.popup {\
    background: #e2e8f0 !important;\
}\
.popup .message-content p {\
    color: #0f172a !important;\
}\
.step.pending .bullet {\
    background-color: #0f172a;\
    background-image: none;\
    border-color: #0f172a;\
}\
.step.pending .line , .step.active .line, .step.success .line{\
    background-color: #149ded;\
}\
.step.success .bullet {\
    background-color: #149ded;\
    border-color: #0f172a;\
}\
.error-message.warn {\
    background-color: transparent;\
}\
.radio-item input:disabled~.checkmark:after {\
  background-color: #149ded;\
}\
.document-status {\
    background-color: transparent !important;\
}\
";
    }
  }
  async submitUserData() {
    // if (!this.userData.email || !this.userData.phone) return
    try {
      this.isLoading = true;
      // this.userDataSubmitted = true
    } finally {
      this.isLoading = false;
    }
  }
  get privateKeyC(): string | null {
    if (this.walletType === "ledger") return null;
    let wallet = this.wallet as SingletonWallet | MnemonicWallet;
    return wallet.ethKey;
  }
  async completeSaftStep() {
    await this.submitUserData();
    this.$emit("changestep", 3);
  }
  handleChange({ value, type }: { value: string; type: string }) {
    switch (type) {
      case "company name (only if company is buying Dijets)":
        this.user.company = value;
        break;
      case "name":
        this.user.name = value;
        this.error.name = !this.nameRegex.test(value);
        break;
      case "surname":
        this.user.surname = value;
        this.error.surname = !this.nameRegex.test(value);
        break;
      case "city":
        this.user.city = value;
        this.error.city = !this.nameRegex.test(value);
        break;
      case "email":
        this.user.email = value;
        this.error.email = !this.emailRegex.test(value);
        break;
      case "phone":
        this.user.phone = value;
        this.error.phone = !this.phoneRegex.test(value);
        break;
      case "street":
        this.user.street = value;
        break;
      case "street2":
        this.user.street2 = value;
        break;
      case "zip code":
        this.user.zip = value;
        break;
      default:
        break;
    }
  }

  get submitUserDataDisabled(): boolean {
    return (
      !this.user.name ||
      !this.user.surname ||
      !this.user.street ||
      !this.user.zip ||
      !this.user.city ||
      !this.user.email ||
      !this.user.agree ||
      this.error.name ||
      this.error.surname ||
      this.error.city ||
      this.error.email
    );
  }

  async submitSaftForm(e: Event) {
    e.preventDefault();
    if (this.submitUserDataDisabled) return;
    else {
      // saving pchain address
      let wallet: MnemonicWallet = this.$store.state.activeWallet;
      localStorage.setItem(
        "Method chain-address",
        wallet.getCurrentAddressPlatform()
      );
      // saving the phone and email in local storage to be used in the KYC process
      localStorage.setItem("Email", this.user.email);
      localStorage.setItem("Phone", this.user.phone);
      if (this.privateKeyC) {
        // getPublicKey
        // put the send email request here
        axios.post("", {
          ...this.user,
          pChainAddress: wallet.getCurrentAddressPlatform(),
          wizard: true,
        });
      }
    }
    this.submitted = true;
  }
}
