


























































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  bnToDjtxX,
  bnToDjtxP,
  bnToDjtxC,
  iDjtxBalance,
  BN,
  Big,
  bnToBigDjtxX,
  bnToBigDjtxC,
} from "@avalabs/avalanche-wallet-sdk";

@Component
export default class Balances extends Vue {
  @Prop() balances!: iDjtxBalance;
  @Prop() stakeAmt!: BN;

  get isReady() {
    return this.balances && this.stakeAmt;
  }

  get xUnlocked() {
    return bnToDjtxX(this.balances.X.unlocked);
  }

  get xLocked() {
    return bnToDjtxX(this.balances.X.locked);
  }

  get xMultisig() {
    return bnToDjtxX(this.balances.X.multisig);
  }

  get pUnlocked() {
    return bnToDjtxX(this.balances.P.unlocked);
  }

  get pLocked() {
    return bnToDjtxX(this.balances.P.locked);
  }

  get pMultisig() {
    return bnToDjtxX(this.balances.P.multisig);
  }

  get pLockedStake() {
    return bnToDjtxX(this.balances.P.lockedStakeable);
  }

  get stake() {
    return bnToDjtxP(this.stakeAmt);
  }

  get cUnlocked() {
    return bnToDjtxC(this.balances.C);
  }

  get totalBalance() {
    if (!this.balances || !this.stakeAmt) {
      return Big(0);
    }

    return bnToBigDjtxX(
      this.balances.X.unlocked
        .add(this.balances.X.locked)
        .add(this.balances.X.multisig)
        .add(this.balances.P.unlocked)
        .add(this.balances.P.locked)
        .add(this.balances.P.lockedStakeable)
        .add(this.balances.P.multisig.add(this.stakeAmt))
    ).add(bnToBigDjtxC(this.balances.C));
  }
}
