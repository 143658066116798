
















import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import ChainImport from "@/components/wallet/advanced/ChainImport.vue";
import SignMessage from "@/components/wallet/advanced/SignMessage/SignMessage.vue";
import VerifyMessage from "@/components/wallet/advanced/VerifyMessage.vue";
import TokenListModal from "@/components/modals/TokenList/TokenListModal.vue";

@Component({
  name: "advanced",
  components: {
    TokenListModal,
    ChainImport,
    SignMessage,
    VerifyMessage,
  },
})
export default class Advanced extends Vue {
  $refs!: {
    token_list: TokenListModal;
  };
  openTokenlist() {
    this.$refs.token_list.open();
  }
}
