




































































































































import "reflect-metadata";
import { Vue, Component, Ref } from "vue-property-decorator";

import TxList from "@/components/wallet/transfer/TxList.vue";
import Big from "big.js";

import NftList from "@/components/wallet/transfer/NftList.vue";

//@ts-ignore
import { QrInput } from "@avalabs/vue_components";
import { ava, avm, isValidAddress } from "../../AVA";
import FaucetLink from "@/components/misc/FaucetLink.vue";
import { ITransaction } from "@/components/wallet/transfer/types";
import { UTXO } from "avalanche/dist/apis/avm";
import { Buffer, BN } from "avalanche";
import TxSummary from "@/components/wallet/transfer/TxSummary.vue";
import { priceDict, IssueBatchTxInput } from "@/store/types";
import { WalletType } from "@/js/wallets/types";
import { bnToBig } from "@/helpers/helper";
import * as bip39 from "bip39";
import FormC from "@/components/wallet/transfer/FormC.vue";
import { ChainIdType } from "@/constants";

import ChainInput from "@/components/wallet/transfer/ChainInput.vue";
import AvaAsset from "../../js/AvaAsset";
import { TxState } from "@/components/wallet/earn/ChainTransfer/types";
@Component({
  components: {
    FaucetLink,
    TxList,
    QrInput,
    NftList,
    TxSummary,
    FormC,
    ChainInput,
  },
})
export default class Transfer extends Vue {
  formType: ChainIdType = "V";
  showAdvanced: boolean = false;
  isAjax: boolean = false;
  addressIn: string = "";
  memo: string = "";
  orders: ITransaction[] = [];
  nftOrders: UTXO[] = [];
  formErrors: string[] = [];
  err = "";

  formAddress: string = "";
  formOrders: ITransaction[] = [];
  formNftOrders: UTXO[] = [];
  formMemo = "";

  isConfirm = false;
  isSuccess = false;
  txId = "";

  canSendAgain = false;
  txState: TxState | null = null;

  $refs!: {
    txList: TxList;
    nftList: NftList;
  };

  confirm() {
    let isValid = this.formCheck();
    if (!isValid) return;

    this.formOrders = [...this.orders];
    this.formNftOrders = [...this.nftOrders];
    this.formAddress = this.addressIn;
    this.formMemo = this.memo;

    this.isConfirm = true;
  }

  cancelConfirm() {
    this.err = "";
    this.formMemo = "";
    this.formOrders = [];
    this.formNftOrders = [];
    this.formAddress = "";
    this.isConfirm = false;
  }

  updateTxList(data: ITransaction[]) {
    this.orders = data;
  }

  updateNftList(val: UTXO[]) {
    this.nftOrders = val;
  }

  formCheck() {
    this.formErrors = [];
    let err = [];

    let addr = this.addressIn;

    let chain = addr.split("-");

    if (chain[0] !== "V") {
      err.push("Invalid address. You can only send to other X addresses.");
    }

    if (!isValidAddress(addr)) {
      err.push("Invalid address.");
    }

    let memo = this.memo;
    if (this.memo) {
      let buff = Buffer.from(memo);
      let size = buff.length;
      if (size > 256) {
        err.push("You can have a maximum of 256 characters in your memo.");
      }

      // Make sure memo isnt mnemonic
      let isMnemonic = bip39.validateMnemonic(memo);
      if (isMnemonic) {
        err.push("You should not put a mnemonic phrase into the Memo field.");
      }
    }

    // Make sure to address matches the bech32 network hrp
    let hrp = ava.getHRP();
    if (!addr.includes(hrp)) {
      err.push("Not a valid address for this network.");
    }

    this.formErrors = err;
    if (err.length === 0) {
      // this.send();
      return true;
    } else {
      return false;
    }
  }

  startAgain() {
    this.clearForm();

    this.txId = "";
    this.isSuccess = false;
    this.cancelConfirm();

    this.orders = [];
    this.nftOrders = [];
    this.formOrders = [];
    this.formNftOrders = [];
  }

  clearForm() {
    this.addressIn = "";
    this.memo = "";

    // Clear transactions list
    this.$refs.txList.reset();

    // Clear NFT list
    if (this.hasNFT) {
      this.$refs.nftList.clear();
    }
  }

  async onsuccess(txId: string) {
    this.isAjax = false;
    this.isSuccess = true;

    this.$store.dispatch("Notifications/add", {
      title: this.$t("transfer.success_title"),
      message: this.$t("transfer.success_msg"),
      type: "success",
    });

    // Update the user's balance
    this.$store.dispatch("Assets/updateUTXOs").then(() => {
      this.updateSendAgainLock();
    });
    this.$store.dispatch("History/updateTransactionHistory");
  }

  updateSendAgainLock() {
    if (!this.wallet.isFetchUtxos) {
      this.canSendAgain = true;
    } else {
      setTimeout(() => {
        this.updateSendAgainLock();
      }, 1000);
    }
  }

  onerror(err: any) {
    this.err = err;
    this.isAjax = false;
    this.$store.dispatch("Notifications/add", {
      title: this.$t("transfer.error_title"),
      message: this.$t("transfer.error_msg"),
      type: "error",
    });
  }

  submit() {
    this.isAjax = true;
    this.err = "";

    let sumArray: (ITransaction | UTXO)[] = [
      ...this.formOrders,
      ...this.formNftOrders,
    ];

    let txList: IssueBatchTxInput = {
      toAddress: this.formAddress,
      memo: Buffer.from(this.formMemo),
      orders: sumArray,
    };

    this.$store
      .dispatch("issueBatchTx", txList)
      .then((res) => {
        this.canSendAgain = false;
        this.waitTxConfirm(res);
        this.txId = res;
      })
      .catch((err) => {
        this.onerror(err);
      });
  }

  async waitTxConfirm(txId: string) {
    let status = await avm.getTxStatus(txId);
    if (status === "Unknown" || status === "Processing") {
      // if not confirmed ask again
      setTimeout(() => {
        this.waitTxConfirm(txId);
      }, 500);
      return false;
    } else if (status === "Dropped") {
      // If dropped stop the process
      this.txState = TxState.failed;
      return false;
    } else {
      // If success display success page
      this.txState = TxState.success;
      this.onsuccess(txId);
    }
  }

  get networkStatus(): string {
    let stat = this.$store.state.Network.status;
    return stat;
  }

  get hasNFT(): boolean {
    // return this.$store.getters.walletNftUTXOs.length > 0
    return this.$store.state.Assets.nftUTXOs.length > 0;
  }

  get faucetLink() {
    let link = process.env.VUE_APP_FAUCET_LINK;
    if (link) return link;
    return null;
  }
  get canSend() {
    if (!this.addressIn) return false;

    if (
      this.orders.length > 0 &&
      this.totalTxSize.eq(new BN(0)) &&
      this.nftOrders.length === 0
    ) {
      return false;
    }

    if (this.orders.length === 0 && this.nftOrders.length === 0) return false;

    return true;
  }
  get totalTxSize() {
    let res = new BN(0);
    for (var i = 0; i < this.orders.length; i++) {
      let order = this.orders[i];
      if (order.amount) {
        res = res.add(this.orders[i].amount);
      }
    }

    return res;
  }
  get djtxTxSize() {
    let res = new BN(0);
    for (var i = 0; i < this.orders.length; i++) {
      let order = this.orders[i];
      if (!order.asset) continue;
      if (order.amount && order.asset.id === this.djtxAsset.id) {
        res = res.add(this.orders[i].amount);
      }
    }

    return res;
  }
  get djtxAsset(): AvaAsset {
    return this.$store.getters["Assets/AssetAVA"];
  }

  get wallet(): WalletType {
    return this.$store.state.activeWallet;
  }

  get txFee(): Big {
    let fee = avm.getTxFee();
    return bnToBig(fee, 9);
  }

  get totalUSD(): Big {
    let totalAsset = this.djtxTxSize.add(avm.getTxFee());
    let bigAmt = bnToBig(totalAsset, 9);
    let usdPrice = this.priceDict.usd;
    let usdBig = bigAmt.times(usdPrice);
    return usdBig;
  }

  get addresses() {
    return this.$store.state.addresses;
  }

  get priceDict(): priceDict {
    return this.$store.state.prices;
  }

  get nftUTXOs(): UTXO[] {
    return this.$store.state.Assets.nftUTXOs;
  }

  deactivated() {
    this.startAgain();
  }

  activated() {
    this.clearForm();

    if (this.$route.query.chain) {
      let chain = this.$route.query.chain as string;
      if (chain === "V") {
        this.formType = "V";
      } else {
        this.formType = "U";
      }
    }

    if (this.$route.query.nft) {
      let utxoId = this.$route.query.nft as string;
      let target = this.nftUTXOs.find((el) => {
        return el.getUTXOID() === utxoId;
      });

      if (target) {
        this.$refs.nftList.addNft(target);
      }
    }
  }
}
