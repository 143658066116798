




































































import { Vue, Component, Watch } from "vue-property-decorator";
import Form from "@/components/wallet/earn/ChainTransfer/Form.vue";
import Guider from "@/components/misc/Guider.vue";
import {
  PublicMnemonicWallet,
  getEthAddressKeyFromAccountKey,
} from "@avalabs/avalanche-wallet-sdk";
import WalletReadonly from "@/views/WalletReadonly.vue";
import { ethers } from "ethers";

@Component({
  components: { WalletReadonly, Form, Guider },
})
export default class Xpub extends Vue {
  xpubXP = "";
  evmAddr = "";
  xpubC = "";
  isLoading = false;
  error = "";
  wallet: PublicMnemonicWallet | null = null;

  get canSubmit() {
    return this.xpubXP.length > 10 && this.evmAddr.length > 9;
  }

  access() {
    try {
      ethers.utils.getAddress(this.evmAddr);
    } catch (e) {
      this.error = " Invalid evm address";
      return;
    }

    try {
      // Not using real xpub for EVM, instead getting C balance
      // directly from network
      const wallet = new PublicMnemonicWallet(this.xpubXP, this.xpubXP);
      this.$router.push({
        name: "wallet_readonly",
        params: {
          //@ts-ignore
          wallet: wallet,
          evmAddress: this.evmAddr,
        },
      });
    } catch (e) {
      this.error = "Invalid XPUB Key.";
    }
  }
}
