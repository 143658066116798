
































import { Vue, Component } from "vue-property-decorator";
import { ImportKeyfileInput, iUserAccountEncrypted } from "@/store/types";
import Identicon from "@/components/misc/Identicon.vue";

@Component({
  components: { Identicon },
})
export default class Account extends Vue {
  password: string = "";
  isLoading: boolean = false;
  error: string = "";

  get index() {
    return this.$route.params.index;
  }
  get accounts() {
    return this.$store.state.Accounts.accounts;
  }

  get account() {
    return this.accounts[this.index];
  }

  created() {
    if (!this.account) {
      this.$router.replace("/access");
      return;
    }
  }

  async access() {
    const { account } = this;
    if (!this.canSubmit || this.isLoading) return;
    if (account == null) return;
    let parent = this;
    this.error = "";
    this.isLoading = true;
    let data: ImportKeyfileInput = {
      password: this.password,
      data: account.wallet,
    };

    setTimeout(() => {
      this.$store
        .dispatch("Accounts/accessAccount", {
          index: this.index,
          pass: this.password,
        })
        .then((res) => {
          parent.isLoading = false;
        })
        .catch((err) => {
          if (err === "INVALID_PASS") {
            parent.error = this.$t("access.password_error").toString();
          } else if (err === "INVALID_VERSION") {
            parent.error = this.$t("access.keystore_error").toString();
          } else {
            parent.error = err.message;
          }
          parent.isLoading = false;
        });
    }, 200);
  }
  onsuccess() {
    this.isLoading = false;
    this.password = "";
  }
  onerror(e: any) {
    this.error = e;
    this.password = "";
    this.isLoading = false;
  }
  get canSubmit(): boolean {
    if (!this.password) {
      return false;
    }
    return true;
  }
}
