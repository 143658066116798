















import "reflect-metadata";
import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "WalletCreated",
})
export default class WalletCreated extends Vue {
  goToWallet() {
    window.open("https://wallet.dijets.io/access");
  }
}
