


















































import { Component, Vue } from "vue-property-decorator";
import NewCollectibleFamily from "@/components/wallet/studio/NewCollectibleFamily.vue";
import MintNft from "@/components/wallet/studio/mint/MintNft.vue";
import { IWalletNftMintDict } from "@/store/types";
import Big from "big.js";
import { bnToBig } from "@/helpers/helper";
import { avm } from "@/AVA";
import { BN } from "avalanche";
@Component({
  name: "studio",
  components: {
    NewCollectibleFamily,
  },
})
export default class Studio extends Vue {
  pageNow: any = null;
  subtitle: string = "";

  goNewNftFamily() {
    this.pageNow = NewCollectibleFamily;
    this.subtitle = "New Collectible Family";
  }

  goMint() {
    this.pageNow = MintNft;
    this.subtitle = "Mint Collectible";
  }

  get nftMintDict(): IWalletNftMintDict {
    // return this.$store.getters.walletNftMintDict
    return this.$store.getters["Assets/nftMintDict"];
  }

  get canMint(): boolean {
    const keys = Object.keys(this.nftMintDict);
    if (keys.length > 0) return true;
    return false;
  }

  deactivated() {
    this.clearPage();
  }

  activated() {
    let utxoId = this.$route.query.utxo;

    if (utxoId) {
      this.goMint();
    }
  }

  // If url has a utxo id, clears it
  clearUrl() {
    let utxoId = this.$route.query.utxo;

    if (utxoId) {
      //@ts-ignore
      this.$router.replace({ query: null });
    }
  }

  clearPage() {
    this.pageNow = null;
    this.subtitle = "";
  }

  cancel() {
    this.clearUrl();
    this.clearPage();
  }
}
