<template>
  <div class="home_view">
    <div class="header">
      <div>
        <div class="head">
          <h1>{{ $t("portfolio.assets") }}</h1>
          <p>
            {{ $t("portfolio.subassets") }}
          </p>
          <div class="asset_select">
            <button
              @click="tab = 'fungibles'"
              :active="tab === `fungibles`"
              data-cy="wallet_fungible"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1px"
                class="sbui-icon"
                style="display: block; vertical-align: middle; height: 11px"
              >
                <path
                  d="M6,1.29C3.109,2.15,1,4.829,1,8s2.109,5.85,5,6.71m15-6.71c0,3.866-3.134,7-7,7s-7-3.134-7-7S10.134,1,14,1s7,3.134,7,7Z"
                ></path>
              </svg>
              <span class="truncate">{{ $t("portfolio.assets1") }}</span>
            </button>
            <div
              data-v-4dc79e44=""
              class="market-panel__buttons__divider"
            ></div>
            <button
              @click="tab = 'collectibles'"
              :active="tab === `collectibles`"
              data-cy="wallet_nft"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1px"
                class="sbui-icon"
                style="display: block; vertical-align: middle; height: 11px"
              >
                <path
                  d="M10.778,17H2.778c-.982,0-1.778-.794-1.778-1.776,0-2.412,0-6.586,0-8.002m5.333,6.222H15.222c.982,0,1.778-.796,1.778-1.778V2.778c0-.982-.796-1.778-1.778-1.778H6.333c-.982,0-1.778,.796-1.778,1.778V11.667c0,.982,.796,1.778,1.778,1.778Z"
                ></path>
              </svg>
              <span class="truncate">{{ $t("portfolio.assets2") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div style="flex-grow: 1"></div>
      <div class="search hover_border">
        <img v-if="$root.theme === 'day'" src="@/assets/search.png" />
        <img v-else src="@/assets/search_night.svg" />
        <input :placeholder="$t('portfolio.search')" v-model="search" />
      </div>
    </div>
    <div class="pages">
      <transition-group name="fade" mode="out-in">
        <fungibles
          v-show="tab === `fungibles`"
          key="fungibles"
          :search="search"
        ></fungibles>
        <collectibles
          v-show="tab === `collectibles`"
          key="collectibles"
          :search="search"
        ></collectibles>
      </transition-group>
    </div>
  </div>
</template>
<script>
import Fungibles from "@/components/wallet/portfolio/Fungibles";
import Collectibles from "@/components/wallet/portfolio/Collectibles";
export default {
  name: "WalletHome",
  data() {
    return {
      search: "",
      tab: "fungibles",
    };
  },
  components: {
    Fungibles,
    Collectibles,
  },
  watch: {
    tab() {
      this.search = "";
    },
  },
};
</script>
<style scoped lang="scss">
@use '../../main';

.home_view {
  display: grid;
  grid-template-rows: max-content 1fr;
}
.header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-bottom: 10px;

  h1 {
    font-size: 20px;
    margin-right: 30px;
  }
  p {
    font-size: 13.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8px !important;
    margin-bottom: 18px !important;
    color: #63696f;
  }

  button {
    box-shadow: rgb(0 0 0 / 1%) 0 0 0 0, rgb(0 0 0 / 1%) 0 0 0 0,
      rgb(0 0 0 / 22%) 0 1px 1px 0, #3c3c48b5 0 0 0 1px, rgb(0 0 0 / 1%) 0 0 0 0,
      rgb(0 0 0 / 1%) 0 0 0 0, rgb(64 68 82 / 8%) 0 2px 5px 0;
    display: flex;
    border: none;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    outline-width: 0;
    outline: 2px solid transparent;
    outline-offset: 2px;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0/0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-size: 0.75rem;
    justify-content: center;
    opacity: 0.9;
    line-height: 1rem;
    text-align: center;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    background-color: #2d2d36;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 0.25rem;
    align-items: center;
    cursor: pointer;
    position: relative;
    -webkit-appearance: button;
    background-image: none;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.012) 0 0 0 0, rgba(0, 0, 0, 0.012) 0 0 0 0,
        rgba(0, 0, 0, 0.22) 0 1px 1px 0, #41414d 0 0 0 1px,
        rgba(0, 0, 0, 0.012) 0 0 0 0, rgba(0, 0, 0, 0.012) 0 0 0 0,
        rgb(64 68 82/8%) 0 2px 5px 0 !important;
      background-color: #2d2d36;
    }

    &[active] {
      opacity: 1;
      box-shadow: rgb(0 0 0 / 1%) 0 0 0 0, rgb(0 0 0 / 1%) 0 0 0 0,
        rgb(0 0 0 / 22%) 0 1px 1px 0, #41ed60d4 0 0 0 1px,
        rgb(0 0 0 / 1%) 0 0 0 0, rgb(0 0 0 / 1%) 0 0 0 0,
        rgb(64 68 82 / 8%) 0 2px 5px 0 !important;
      background-color: #33b57ba6;
      color: #ffffff;
    }
  }
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 4px;
  font-weight: normal;
  color: #ffffff;
  &:hover {
    color: rgb(255, 255, 255);
  }
  &[active] {
    color: rgb(255, 255, 255);
  }
}

.market-panel__buttons__divider {
  background-color: #47484896;
  width: 1px;
  margin: 0px 20px;
}
hr {
  max-width: initial;
  position: relative;
  width: 100%;
  margin: 20px 0px 12px;
  border: 0px;
  height: 14.36px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='15' viewBox='0 0 10 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.432617' y='13.8564' width='16' height='1' transform='rotate(-60 0.432617 13.8564)' fill='%2350525B'/%3E%3C/svg%3E%0A");
  background-repeat: repeat-x;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.asset_select {
  display: flex;
  margin-top: 24px;
  width: max-content;
}
.search {
  background-color: rgb(0 0 0 / 20%);
  border-radius: 4px;
  /*flex-grow: 1;*/
  height: 40px;
  padding: 4px;
  display: flex;
  align-items: center;
  font-size: 13px;
  flex-basis: 342px;
  flex-shrink: 1;
  border: 1px solid transparent;

  $icon_w: 30px;

  img {
    border-radius: 4px;
    padding: 8px 0px;
    background-color: rgb(95 95 104 / 16%);
    /*height: 100%;*/
    height: $icon_w;
    width: $icon_w;
    object-fit: contain;
  }

  input {
    padding: 0px 16px;
    outline: none;
    border: none !important;
    flex-grow: 1;
    color: var(--primary-color);

    &::placeholder {
      color: #b3b7d387;
    }
  }
}

.pages {
  /*margin-top: 30px;*/
}

@include main.mobile-device {
  .header {
    margin-top: 12px;
    display: block;
    h1 {
      font-size: 20px;
      margin-right: 30px;
      margin-bottom: 12px;
    }
    p {
      display: none;
    }

    > div {
      overflow: visible;
      display: flex;
      width: 330px;
    }
    button {
      flex-grow: 1;
      padding: 2px 20px;
      border-radius: 0px;
      margin: 0;
      font-size: 11px;
      justify-content: center;
      border-radius: 4px;
    }
  }

  .search.hover_border {
    display: none !important;
  }

  .search {
    margin: 15px 0px;
  }

  .pages {
    /*min-height: 100vh;*/
    /*padding-bottom: 30px;*/
  }

  .home_view {
    margin-top: 10px;
  }
}

@include main.medium-device {
  .header {
    button {
      font-size: 13px;

      &[active] {
        border-bottom-width: 2px;
      }
    }
  }

  .search {
    //margin: 15px 0px;
    //flex-basis: 100%;
    flex-grow: 1;
    height: 36px;
    flex-basis: auto;

    img {
      padding: 6px;
      height: 22px;
      width: 22px;
    }
  }
}
</style>
