
























import { Vue, Component } from "vue-property-decorator";
@Component({
  metaInfo: () => {
    const description =
      "Access your stored crypto assets in a simple fashion, yet highly secure, and non-custodial fashion. Your Dijets wallet is a few clicks away!";
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
        {
          vmid: "og:description",
          name: "description",
          content: description,
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "Access Stored Crypto Assets | Dijets Wallet",
        },
      ],
      title: "Access Stored Crypto Assets",
    };
  },
})
export default class Access extends Vue {}
