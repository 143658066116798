








import { Vue, Component } from "vue-property-decorator";
import ToSContent from "@/components/misc/ToSContent.vue";
import ToSCoreContent from "@/components/misc/ToSCoreContent.vue";
@Component({
  components: { ToSContent, ToSCoreContent },
})
export default class Legal extends Vue {
  get isCore() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("core")) {
      return true;
    }
    return false;
  }
}
