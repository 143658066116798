


































import "reflect-metadata";
import { Vue, Component } from "vue-property-decorator";
import Spinner from "@/components/misc/Spinner.vue";
import * as bip39 from "bip39";

import MnemonicPhrase from "@/js/wallets/MnemonicPhrase";
import Modal from "@/components/modals/Modal.vue";
import { BN, WalletNameType, WalletType } from "@avalabs/avalanche-wallet-sdk";
import MnemonicWallet from "@/js/wallets/MnemonicWallet";
import { bnToBig } from "@/helpers/helper";
import Big from "big.js";
import AvaAsset from "@/js/AvaAsset";
import CreateWalletStep from "./CreateWalletStep.vue";
import Saft from "./Saft.vue";
import KycVerification from "./KycVerification.vue";

@Component({
  components: {
    Spinner,
    CreateWalletStep,
    KycVerification,
    Saft,
  },
})
export default class CreateWallet extends Vue {
  // TODO: We do not need to create keyPair, only mnemonic is sufficient
  isLoad: boolean = false;
  isLoading: boolean = false;
  step = 1;
  keyPhrase: MnemonicPhrase | null = null;
  isSecured: boolean = false;
  isVerified: boolean = false;
  verificationCompleted = false;
  created() {
    this.createKey();
  }
  get canVerify(): boolean {
    return this.isSecured ? true : false;
  }

  get verificatiionColor() {
    return this.isVerified ? "#a9efbf" : "#F5F6FA";
  }

  createKey(): void {
    this.isSecured = false;
    let mnemonic = bip39.generateMnemonic(256);
    this.keyPhrase = new MnemonicPhrase(mnemonic);
  }

  $refs!: {
    modal: Modal;
  };
  getVerificationStatus() {
    this.verificationCompleted = true;
  }
  get canSubmit(): boolean {
    // if(!this.rememberValid) return false;
    return true;
  }
  verifyMnemonic() {
    // @ts-ignore
    this.$refs.verify.open();
  }

  get wallet() {
    let wallet: MnemonicWallet = this.$store.state.activeWallet;
    return wallet;
  }

  /*********************  getbalance *********************/
  // updateBalance(): void {
  //     this.$store.dispatch('Assets/updateUTXOs')
  //     this.$store.dispatch('History/updateTransactionHistory')
  // }
  get isUpdateBalance(): boolean {
    if (!this.wallet) return true;
    return this.wallet.isFetchUtxos;
  }
  get ava_asset(): AvaAsset | null {
    let ava = this.$store.getters["Assets/AssetAVA"];
    return ava;
  }
  get evmUnlocked(): BN {
    if (!this.wallet) return new BN(0);
    // convert from ^18 to ^9
    let bal = this.wallet.ethBalance;
    return bal.div(new BN(Math.pow(10, 9).toString()));
  }

  get totalBalance(): BN {
    if (!this.ava_asset) return new BN(0);

    let tot = this.ava_asset.getTotalAmount();
    // add EVM balance
    tot = tot.add(this.evmUnlocked);
    return tot;
  }

  get totalBalanceBig(): Big {
    if (this.ava_asset) {
      let denom = this.ava_asset.denomination;
      let bigTot = bnToBig(this.totalBalance, denom);
      return bigTot;
    }
    return Big(0);
  }
  get balanceText(): string {
    if (this.ava_asset !== null) {
      let denom = this.ava_asset.denomination;
      return this.totalBalanceBig.toLocaleString(denom);
    } else {
      return "?";
    }
  }

  get balanceTextLeft(): string {
    if (this.isUpdateBalance) return "--";
    let text = this.balanceText;
    if (text.includes(".")) {
      let left = text.split(".")[0];
      return left;
    }
    return text;
  }
  /*********************  getbalance *********************/
  async complete() {
    let result = await this.access();
    this.isLoad = false;
    this.isVerified = true;
  }
  nextStep() {}
  nextStepKyc() {}
  async access(): Promise<void> {
    if (!this.keyPhrase) return;
    this.isLoad = true;
    let parent = this;
    return await parent.$store.dispatch(
      "accessWallet",
      this.keyPhrase!.getValue()
    );
  }
  changeStep(s: number) {
    this.step = s;
  }
}
