


































































































import "reflect-metadata";
import { Vue, Component, Prop } from "vue-property-decorator";

import AddValidator from "@/components/wallet/earn/Validate/AddValidator.vue";
import AddDelegator from "@/components/wallet/earn/Delegate/AddDelegator.vue";
import { BN } from "avalanche/dist";
import UserRewards from "@/components/wallet/earn/UserRewards.vue";
import { bnToBig } from "@/helpers/helper";
import Big from "big.js";

@Component({
  name: "earn",
  components: {
    UserRewards,
    AddValidator,
    AddDelegator,
  },
})
export default class Earn extends Vue {
  pageNow: any = null;
  subtitle: string = "";
  intervalID: any = null;

  addValidator() {
    this.pageNow = AddValidator;
    this.subtitle = this.$t("earn.subtitle1") as string;
  }
  addDelegator() {
    this.pageNow = AddDelegator;
    this.subtitle = this.$t("earn.subtitle2") as string;
  }
  transfer() {
    this.$router.replace("/wallet/cross_chain");
  }

  viewRewards() {
    this.pageNow = UserRewards;
    this.subtitle = this.$t("earn.subtitle4") as string;
  }
  cancel() {
    this.pageNow = null;
    this.subtitle = "";
  }

  updateValidators() {
    this.$store.dispatch("Platform/update");
  }

  created() {
    this.updateValidators();
    this.intervalID = setInterval(() => {
      this.updateValidators();
    }, 15000);
  }

  deactivated() {
    this.cancel();
  }

  destroyed() {
    clearInterval(this.intervalID);
  }

  get platformUnlocked(): BN {
    return this.$store.getters["Assets/walletPlatformBalance"].available;
  }

  get platformLockedStakeable(): BN {
    // return this.$store.getters.walletPlatformBalanceLockedStakeable
    return this.$store.getters["Assets/walletPlatformBalanceLockedStakeable"];
  }

  get totBal(): BN {
    return this.platformUnlocked.add(this.platformLockedStakeable);
  }

  get pNoBalance() {
    return this.platformUnlocked.add(this.platformLockedStakeable).isZero();
  }

  get canDelegate(): boolean {
    let bn = this.$store.state.Platform.minStakeDelegation;
    if (this.totBal.lt(bn)) {
      return false;
    }
    return true;
  }

  get canValidate(): boolean {
    let bn = this.$store.state.Platform.minStake;
    if (this.totBal.lt(bn)) {
      return false;
    }
    return true;
  }

  get minStakeAmt(): Big {
    let bn = this.$store.state.Platform.minStake;
    return bnToBig(bn, 9);
  }

  get minDelegationAmt(): Big {
    let bn = this.$store.state.Platform.minStakeDelegation;
    return bnToBig(bn, 9);
  }
}
