










































































































































import { Vue, Component } from "vue-property-decorator";
import LedgerButton from "@/components/Ledger/LedgerButton.vue";
import TrezorButton from "@/components/Trezor/TrezorButton.vue";
import AccountsFound from "@/components/Access/AccountsFound.vue";
import AddERC20TokenModal from "@/components/modals/AddERC20TokenModal.vue";
import MenuTip from "@/components/misc/MenuTip.vue";
import MenuTip2 from "@/components/misc/MenuTip2.vue";
import MenuTip3 from "@/components/misc/MenuTip3.vue";
import MenuTip4 from "@/components/misc/MenuTip4.vue";
// import TorusGoogle from '@/components/Torus/TorusGoogle.vue'
//import TorusGoogle from TorusGoogle
import ToS from "@/components/misc/ToS.vue";
import ImageDayNight from "@/components/misc/ImageDayNight.vue";

@Component({
  components: {
    ImageDayNight,
    AddERC20TokenModal,
    MenuTip,
    MenuTip2,
    MenuTip3,
    MenuTip4,
    ToS,
    LedgerButton,
    TrezorButton,
    AccountsFound,
  },
})
export default class Menu extends Vue {
  $refs!: {
    add_token_modal: AddERC20TokenModal;
  };
  addToken() {
    this.$refs.add_token_modal.open();
  }
}
