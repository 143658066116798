





























































import { Vue, Component } from "vue-property-decorator";

import FileInput from "../../components/misc/FileInput.vue";
// import RememberKey from "../../components/misc/RememberKey.vue";
import { ImportKeyfileInput } from "@/store/types";
import Guider from "@/components/misc/Guider.vue";
import { AllKeyFileTypes } from "@/js/IKeystore";

@Component({
  components: {
    // RememberKey,
    FileInput,
    Guider,
  },
})
export default class Keystore extends Vue {
  pass: string = "";
  file: File | null = null;
  fileText: string | null = null;
  // rememberPass: string|null = null;
  // rememberValid: boolean = true;
  isLoading: boolean = false;
  error: string = "";

  onfile(val: File) {
    this.file = val;
    let parent = this;

    let reader = new FileReader();
    reader.addEventListener("load", async () => {
      let res = reader.result as string;
      parent.fileText = res;
    });
    reader.readAsText(val);
  }

  // isRememberValid(val: boolean){
  //     this.rememberValid = val;
  // }
  access() {
    if (!this.canSubmit || this.isLoading) return;

    let parent = this;
    this.error = "";

    let fileData: AllKeyFileTypes;
    try {
      fileData = JSON.parse(this.fileText as string);
    } catch (e) {
      this.error = `${this.$t("access.json_error")}`;
      return;
    }

    // console.log(this.fileText);
    // return;

    // let rememberPass = this.rememberPass;
    let data: ImportKeyfileInput = {
      password: this.pass,
      data: fileData,
    };

    this.isLoading = true;

    setTimeout(() => {
      this.$store
        .dispatch("importKeyfile", data)
        .then((res) => {
          parent.isLoading = false;

          // if(rememberPass){
          //     parent.$store.dispatch('rememberWallets', rememberPass)
          // }
        })
        .catch((err) => {
          console.log(err);
          if (err === "INVALID_PASS") {
            parent.error = this.$t("access.password_error").toString();
          } else if (err === "INVALID_VERSION") {
            parent.error = this.$t("access.keystore_error").toString();
          } else {
            parent.error = err.message;
          }
          parent.isLoading = false;
        });
    }, 200);
  }

  get canSubmit(): boolean {
    if (!this.file || !this.pass || !this.fileText) {
      return false;
    }

    return true;
  }
}
